import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin( ScrollTrigger );

const btn = document.getElementById( 'btn' );

btn.addEventListener( 'click', function() {
    gsap.to(".box", { 
        x: 200,
    });
} );